import React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import CheckboxOutline from "../static/svg/icon-checkbox-outline.inline.svg";
import LayoutSub from "../components/layoutSub";
import SEO from "../components/seo";




function Covid19({ location, pageContext }) {
  const data = useStaticQuery(graphql`
  query  covidImg {
    largeImg: file(relativePath: {eq: "corona_960_pink.jpg"}) {
        childImageSharp {
        gatsbyImageData(

             placeholder: BLURRED
             formats: [AUTO, WEBP]
             layout: FULL_WIDTH

             )
        }
    }
    smallImg: file(relativePath: {eq: "corona_656_pink.jpg"}) {
        childImageSharp {
            gatsbyImageData(

             placeholder: BLURRED
             formats: [AUTO, WEBP]
             layout: FULL_WIDTH
            )
       }
   }
}

`)

const images = withArtDirection(getImage(data.smallImg.childImageSharp.gatsbyImageData),
[
 {
   media: "(min-width: 640px)",
   image: getImage(data.largeImg.childImageSharp.gatsbyImageData),
 },
])

  const { breadcrumb: { crumbs } } = pageContext;
  
  const {pathname} = location;
  const pagetitle = "新型コロナウィルス対策";
  const description = "安心して整体を受けていただけるように、新型コロナ感染症対策に取り組んでおります。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

  return (


    <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
      />

      <section>
        <h1 className=" my-2 heading-b">新型コロナウィルスへの対策</h1>
        <p>
          安心して整体を受けていただけるように、新型コロナ感染症対策に取り組んでおります。
        </p>
        <div className="w-full">
          <GatsbyImage 
           className="art-directed-covid"
           image={images} 
           alt="新型コロナウィルス対策を実施しているので安心して整体を受けていただけます。" />
        </div>


        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1" />
          風通しの良い部屋での整体です。予約制のため不特定多数の方との接触はありません。
        </p>
        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1"  />
          スタッフは、施術前後の手洗いと、施術中のマスク着用を徹底しております。
        </p>
        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1"  />
          施術前後には、窓を開けての換気、ベッド・シーツの消毒をしております。
        </p>

        <br />
        <p className="text-center text-lg">
          <span className="marker">クライアント様へのお願い</span>
        </p>
        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1"  />
          ご来院時には、手指の消毒をお願いいたします。
        </p>
        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1"  />
          施術中は、マスクの着用をお願いいたします。
        </p>
        <p className="mt-6 border-b-2 border-dotted border-gray-800 pb-1">
          <CheckboxOutline className="w-5 h-5 align-text-bottom inline pb-0.5 ml-1 mr-1"  />
          発熱や咳のある方は、ご遠慮なくキャンセルをお申し出くださいませ。
        </p>



      </section>
    </LayoutSub>
      );
}

export default Covid19;